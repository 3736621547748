
.first_bullet li::before {
  content: "";
  color: #d7d44b;
  font-weight: bold;
  display: inline-block;
  width: 0px;
}
.second_bullet li::before {
  content: "\2022";
  color: rgb(6, 3, 43);
  font-weight: bold;
  display: inline-block;
  width: 1em;
}
.first_bullet {
  list-style: none;
  font-weight: 700;
  text-align: center;
  padding-inline-start: 0px;
}
.second_bullet {
  margin-top: 5px;
  list-style: none;
  text-align: justify;
  padding-inline-start: 0px;
  font-size: small;
  color: #5b6874;
  font-weight: 400;
}
.second_bullet li {
  margin-top: 5px;
  margin-bottom: 7px;
}
.second_bullet_title {
  font-weight: 700;
  font-size: medium;
}
.max_image {
  max-width: 100%;
}
.image_65 {
  max-width: 65%;
}
.nav_style {
  padding-bottom: 0px;
  padding-top: 0px;
  color: black !important;
}
#nav-tabs .active {
  border-bottom-style: solid;
  border-bottom-color: #d7d44b;
}
@media (min-width: 992px) {
  .border_right_gray {
    border-right: solid gray 1px;
  }

  .nav_style {
    padding-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 0px;
    color: black !important;
  }
  
  .first_bullet {
    list-style: none;
    font-weight: 700;
    padding-inline-start: 40px;
    text-align: left;
  }

  .second_bullet {
    margin-top: 5px;
    list-style: none;
    font-size: small;
    padding-inline-start: revert;
    color: #5b6874;
    font-weight: 400;
  }

  .first_bullet li::before {
    content: "\2022";
    color: #d7d44b;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  .second_bullet li::before {
    content: "\2022";
    color: rgb(6, 3, 43);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}