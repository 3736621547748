.service_title {
  font-family: "Rajdhani", sans-serif;
}
.service_subtitle {
  color: #6dbae4;
}
.info_button {
  background-color: #d7d44b;
  border-color: #d7d44b;
  color: black;
}
.title_services {
  color: #030c3e;
}

.content_services {
  color: #5b6874;
  font-size: small;
}

@media (min-width: 992px) {
  .content_services {
    height: 145px;
  }

  .title_services {
    color: #030c3e;
    height: 70px;
  }
}
@media (min-width: 768px) { 

}