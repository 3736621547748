.mungia_input {
  background-color: aliceblue;
  border: none;
}
.edecan_photo{
    position: absolute;
    margin-bottom: -16px;
    bottom: 0;
}
.position_relative{
    position: relative;
}
.office_corporate{
  color: #030c3e;
  font-size: 22px;
}

.office_title{
  color: #5b5874;
  font-size: medium;
}

.office_address{
  font-size: small;

}
.office_contact{
  font-size: small;
  font-weight: bold;
  cursor: pointer;
}

.contact_bullet {
  list-style: none;
}
.contact_bullet li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  float: left;
  margin-top: -4px;
  width: 1em;
  margin-left: -1em;
}