.carousel_underbar {
  width: 100%;
  height: 5px;
}
.footer_bg {
  background-image: url("/assets/images/footer.png");
}
.black_overlay {
  background: rgb(29,172,211);
  background: linear-gradient(0deg, rgba(29,172,211,1) 0%, rgba(1,50,57,1) 100%) !important;
}
.paddingt20 {
  padding-top: 0px;
}
@media (min-width: 992px) {
  .paddingt20 {
    padding-top: 20px;
  }
  .footer_bg {
    background-image: url("/assets/images/footer.png");
    background-repeat: round;
  }

  .black_overlay {
    background: rgb(29,172,211);
    background: linear-gradient(0deg, rgba(29,172,211,1) 0%, rgba(1,50,57,1) 100%) !important;
    /*
    background: none !important;
    background-color: #00000073 !important; */
  }
}
