@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}
.App_no_x_scroll {
  overflow-x: hidden;
}
.font-size15 {
  font-size: 15px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*backgrounds*/
.aqua_green_bg {
  background-color: #55b7d2;
  display: none;
}
.whitesmoke_bg {
  background-color: whitesmoke;
}
.black_bg {
  background-color: #282c34;
}
.color_white {
  color: white;
}

.carousel_underbar {
  width: 100%;
  height: 5px;
}

.justify_text {
  text-align: justify;
}
.padding0 {
  padding: 0px;
}

@media (min-width: 768px) {
  .padding0 {
    padding: 15px;
  }
}

.header_internal_bg {
  background-image: url("/assets/images/internalbg.jpg");
  background-repeat: round;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.color_white {
  color: white;
}
.color_yellowish {
  color: #e9e75e;
}
.color_aqua {
  color: #61dafb;
}
.bold_f {
  font-weight: bold;
}
.font_size_small {
  font-size: small;
}
.color_text_notblack1 {
  color: #5b6874;
}
.color_text_notblack2 {
  color: #030c3e;
}
@media (min-width: 992px) {
  .helper_negative_margin {
    margin-left: -45px;
  }

  .nav_style_2 {
    padding-bottom: 0px;
    padding-top: 0px;
    color: black !important;
  }
}
.desktop_overlayed_header_container {
  display: flex;
  margin-top: 10px;
  margin-bottom: -10px;
  z-index: 3000;
  position: relative;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 10%,
    rgba(255, 255, 255, 1) 100%
  );
}
.desktop_internal_header_container {
  display: flex;
  margin-top: 10px;
  margin-bottom: -10px;
  z-index: 3000;
  height: 120px;
  position: relative;
  background: rgb(255, 255, 255);
}
.desktop_logo {
  position: absolute;
  left: 10%;
  cursor: pointer;
}
.desktop_logo_img {
  width: 110px;
  height: 100%;
  margin-top: 10px;
}
.desktop_menu_container {
  position: absolute;
  right: 10%;
}
.desktop_call_us {
  font-size: 13px;
  text-align: right;
  color:#030c3e;
}
.desktop_phone {
  color: #55b7d2;
  font-size: 15px;
}
.menu-centered {
}
.desktop_menu_item {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}
.desktop_menu_item a{
    color:black;
    cursor: pointer;
}
.border_right_aqua {
  border-right: solid #55b7d2 1px;
}
.desktop_actual_menu_container {
  display: flex;
  flex-flow: row-reverse;
  font-size: 13px;
  margin-left: 15px;
  margin-right: -15px;
  letter-spacing: 2px;
}
.desktop_fake_bg {
  position: absolute;
  width: 100%;
  margin-top: -20px;
  height: 170px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 1) 100%
  );
}

.nav_bar_bg{
  background-color: #1dacd3 !important;
}
.high_index{
  z-index: 1;

}
.fake_nav_bar_bg{
  position: absolute;
  margin-left: -16px;
  top: 0;
  height: 76px;
  width: 101%;
  background: rgb(29,172,211);
  background: linear-gradient(0deg, rgba(29,172,211,1) 3%, rgba(1,50,57,1) 100%) !important;
  

}
.text_color_white{
  color:white;
}
.text_color_white a{
  color:white;
}
.cursor_pointer{
  cursor: pointer;
}
.carousel_underbar {
  width: 100%;
  height: 5px;
}
.footer_bg {
  background-image: url("/assets/images/footer.png");
}
.black_overlay {
  background: rgb(29,172,211);
  background: linear-gradient(0deg, rgba(29,172,211,1) 0%, rgba(1,50,57,1) 100%) !important;
}
.paddingt20 {
  padding-top: 0px;
}
@media (min-width: 992px) {
  .paddingt20 {
    padding-top: 20px;
  }
  .footer_bg {
    background-image: url("/assets/images/footer.png");
    background-repeat: round;
  }

  .black_overlay {
    background: rgb(29,172,211);
    background: linear-gradient(0deg, rgba(29,172,211,1) 0%, rgba(1,50,57,1) 100%) !important;
    /*
    background: none !important;
    background-color: #00000073 !important; */
  }
}

.image_carousel{
    position: relative;
    width: 100%;
}
.caption_bg1{
    display: flex;
    align-items: center;
    top:20px;
}
.caption_bg2{  
    display: flex;
    align-items: center;
    top:20px;
}
.text_carousel{

    font-size: medium;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    
}
.text_carousel2{

    font-size: medium;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    
}
.text_carousel3{

    font-size: medium;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    
}
@media (min-width: 576px) {  }

@media (min-width: 768px) { 

    .image_carousel{
        position: relative;
        width: 100%;
        padding-top: 0px;
    }
    .text_carousel{
        width:50%;
        font-size: 1.75rem;
        -webkit-text-stroke-width: 0px;
    } 
    .text_carousel2{
        text-align: center;
        font-size: 1.75rem;
        -webkit-text-stroke-width: 0px;
    } 
    .text_carousel3{
        width:50%;
        text-align: center;
        font-size: 1.75rem;
        -webkit-text-stroke-width: 0px;
    } 
    .caption_bg1{
        display: flex;
        align-items: center;
        top: auto;
        bottom: 47%;
    }
    .caption_bg2{  
        display: flex;
        align-items: center;
        left: 57%;
        top:revert;
    }
    .caption_bg3{  
        display: flex;
        align-items: center;
        left: 4%;
        bottom: 4px;
    }
    .caption_bg4{  
        display: flex;
        align-items: center;
        right: 7%;
        left: 63%;
    }
 }

 .carousel-indicators,
 .carousel-indicators li {
     visibility: hidden;
 }
@media (min-width: 992px) { 
    .carousel-indicators,
  .carousel-indicators li {
      visibility: visible;
  }
}

@media (min-width: 1200px) { 
 }
.service_title {
  font-family: "Rajdhani", sans-serif;
}
.service_subtitle {
  color: #6dbae4;
}
.info_button {
  background-color: #d7d44b;
  border-color: #d7d44b;
  color: black;
}
.title_services {
  color: #030c3e;
}

.content_services {
  color: #5b6874;
  font-size: small;
}

@media (min-width: 992px) {
  .content_services {
    height: 145px;
  }

  .title_services {
    color: #030c3e;
    height: 70px;
  }
}
@media (min-width: 768px) { 

}

.first_bullet li::before {
  content: "";
  color: #d7d44b;
  font-weight: bold;
  display: inline-block;
  width: 0px;
}
.second_bullet li::before {
  content: "\2022";
  color: rgb(6, 3, 43);
  font-weight: bold;
  display: inline-block;
  width: 1em;
}
.first_bullet {
  list-style: none;
  font-weight: 700;
  text-align: center;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
.second_bullet {
  margin-top: 5px;
  list-style: none;
  text-align: justify;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  font-size: small;
  color: #5b6874;
  font-weight: 400;
}
.second_bullet li {
  margin-top: 5px;
  margin-bottom: 7px;
}
.second_bullet_title {
  font-weight: 700;
  font-size: medium;
}
.max_image {
  max-width: 100%;
}
.image_65 {
  max-width: 65%;
}
.nav_style {
  padding-bottom: 0px;
  padding-top: 0px;
  color: black !important;
}
#nav-tabs .active {
  border-bottom-style: solid;
  border-bottom-color: #d7d44b;
}
@media (min-width: 992px) {
  .border_right_gray {
    border-right: solid gray 1px;
  }

  .nav_style {
    padding-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 0px;
    color: black !important;
  }
  
  .first_bullet {
    list-style: none;
    font-weight: 700;
    -webkit-padding-start: 40px;
            padding-inline-start: 40px;
    text-align: left;
  }

  .second_bullet {
    margin-top: 5px;
    list-style: none;
    font-size: small;
    -webkit-padding-start: revert;
            padding-inline-start: revert;
    color: #5b6874;
    font-weight: 400;
  }

  .first_bullet li::before {
    content: "\2022";
    color: #d7d44b;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  .second_bullet li::before {
    content: "\2022";
    color: rgb(6, 3, 43);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
.mungia_input {
  background-color: aliceblue;
  border: none;
}
.edecan_photo{
    position: absolute;
    margin-bottom: -16px;
    bottom: 0;
}
.position_relative{
    position: relative;
}
.office_corporate{
  color: #030c3e;
  font-size: 22px;
}

.office_title{
  color: #5b5874;
  font-size: medium;
}

.office_address{
  font-size: small;

}
.office_contact{
  font-size: small;
  font-weight: bold;
  cursor: pointer;
}

.contact_bullet {
  list-style: none;
}
.contact_bullet li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  float: left;
  margin-top: -4px;
  width: 1em;
  margin-left: -1em;
}
