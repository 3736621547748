.image_carousel{
    position: relative;
    width: 100%;
}
.caption_bg1{
    display: flex;
    align-items: center;
    top:20px;
}
.caption_bg2{  
    display: flex;
    align-items: center;
    top:20px;
}
.text_carousel{

    font-size: medium;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    
}
.text_carousel2{

    font-size: medium;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    
}
.text_carousel3{

    font-size: medium;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    
}
@media (min-width: 576px) {  }

@media (min-width: 768px) { 

    .image_carousel{
        position: relative;
        width: 100%;
        padding-top: 0px;
    }
    .text_carousel{
        width:50%;
        font-size: 1.75rem;
        -webkit-text-stroke-width: 0px;
    } 
    .text_carousel2{
        text-align: center;
        font-size: 1.75rem;
        -webkit-text-stroke-width: 0px;
    } 
    .text_carousel3{
        width:50%;
        text-align: center;
        font-size: 1.75rem;
        -webkit-text-stroke-width: 0px;
    } 
    .caption_bg1{
        display: flex;
        align-items: center;
        top: auto;
        bottom: 47%;
    }
    .caption_bg2{  
        display: flex;
        align-items: center;
        left: 57%;
        top:revert;
    }
    .caption_bg3{  
        display: flex;
        align-items: center;
        left: 4%;
        bottom: 4px;
    }
    .caption_bg4{  
        display: flex;
        align-items: center;
        right: 7%;
        left: 63%;
    }
 }

 .carousel-indicators,
 .carousel-indicators li {
     visibility: hidden;
 }
@media (min-width: 992px) { 
    .carousel-indicators,
  .carousel-indicators li {
      visibility: visible;
  }
}

@media (min-width: 1200px) { 
 }