.header_internal_bg {
  background-image: url("/assets/images/internalbg.jpg");
  background-repeat: round;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.color_white {
  color: white;
}
.color_yellowish {
  color: #e9e75e;
}
.color_aqua {
  color: #61dafb;
}
.bold_f {
  font-weight: bold;
}
.font_size_small {
  font-size: small;
}
.color_text_notblack1 {
  color: #5b6874;
}
.color_text_notblack2 {
  color: #030c3e;
}
@media (min-width: 992px) {
  .helper_negative_margin {
    margin-left: -45px;
  }

  .nav_style_2 {
    padding-bottom: 0px;
    padding-top: 0px;
    color: black !important;
  }
}