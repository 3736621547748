.desktop_overlayed_header_container {
  display: flex;
  margin-top: 10px;
  margin-bottom: -10px;
  z-index: 3000;
  position: relative;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 10%,
    rgba(255, 255, 255, 1) 100%
  );
}
.desktop_internal_header_container {
  display: flex;
  margin-top: 10px;
  margin-bottom: -10px;
  z-index: 3000;
  height: 120px;
  position: relative;
  background: rgb(255, 255, 255);
}
.desktop_logo {
  position: absolute;
  left: 10%;
  cursor: pointer;
}
.desktop_logo_img {
  width: 110px;
  height: 100%;
  margin-top: 10px;
}
.desktop_menu_container {
  position: absolute;
  right: 10%;
}
.desktop_call_us {
  font-size: 13px;
  text-align: right;
  color:#030c3e;
}
.desktop_phone {
  color: #55b7d2;
  font-size: 15px;
}
.menu-centered {
}
.desktop_menu_item {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}
.desktop_menu_item a{
    color:black;
    cursor: pointer;
}
.border_right_aqua {
  border-right: solid #55b7d2 1px;
}
.desktop_actual_menu_container {
  display: flex;
  flex-flow: row-reverse;
  font-size: 13px;
  margin-left: 15px;
  margin-right: -15px;
  letter-spacing: 2px;
}
.desktop_fake_bg {
  position: absolute;
  width: 100%;
  margin-top: -20px;
  height: 170px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 25%,
    rgba(255, 255, 255, 1) 100%
  );
}

.nav_bar_bg{
  background-color: #1dacd3 !important;
}
.high_index{
  z-index: 1;

}
.fake_nav_bar_bg{
  position: absolute;
  margin-left: -16px;
  top: 0;
  height: 76px;
  width: 101%;
  background: rgb(29,172,211);
  background: linear-gradient(0deg, rgba(29,172,211,1) 3%, rgba(1,50,57,1) 100%) !important;
  

}
.text_color_white{
  color:white;
}
.text_color_white a{
  color:white;
}
.cursor_pointer{
  cursor: pointer;
}